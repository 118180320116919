import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61')
];

export const server_loads = [0,2,3,4,7,9];

export const dictionary = {
		"/": [13],
		"/(auth)/accounts/launch": [34,[6]],
		"/(auth)/accounts/login": [~35,[6]],
		"/(auth)/accounts/password/reset_password/[token]": [~37,[6]],
		"/(auth)/accounts/password/reset": [36,[6]],
		"/(auth)/accounts/signout/[slug]": [~38,[6]],
		"/(auth)/accounts/signup": [~39,[6]],
		"/(auth)/accounts/signup/activate": [~40,[6]],
		"/(auth)/accounts/success": [41,[6]],
		"/(admin)/admin/bookings/bookingSets": [~15,[2,3]],
		"/(admin)/admin/bookings/bookingSets/[id]": [~16,[2,3]],
		"/(admin)/admin/bookings/booking/[id]": [~14,[2,3]],
		"/(admin)/admin/bookings/cancelled": [~17,[2,3]],
		"/(admin)/admin/bookings/completed": [~18,[2,3]],
		"/(admin)/admin/bookings/upcoming": [19,[2,3]],
		"/(admin)/admin/client_side": [20,[2]],
		"/(admin)/admin/home": [~21,[2]],
		"/(admin)/admin/invite_earn": [22,[2]],
		"/(admin)/admin/locations": [23,[2]],
		"/(admin)/admin/locations/[location_id]": [~24,[2]],
		"/(admin)/admin/payments": [25,[2,4]],
		"/(admin)/admin/payments/add_card": [~26,[2,4]],
		"/(admin)/admin/payments/buy": [27,[2,4]],
		"/(admin)/admin/payments/card_added": [~28,[2,4]],
		"/(admin)/admin/profile": [~29,[2]],
		"/(admin)/admin/sweepcred": [~30,[2]],
		"/(admin)/admin/sweepstars": [~31,[2]],
		"/(admin)/admin/sweepstars/sweepstar/[username]": [~32,[2]],
		"/(admin)/admin/vouchers": [~33,[2]],
		"/(info)/app": [~59,[11]],
		"/(funnel)/(booking)/booking/accounts/login": [~43,[7,8]],
		"/(funnel)/(booking)/booking/accounts/password/reset_password/[token]": [~45,[7,8]],
		"/(funnel)/(booking)/booking/accounts/password/reset": [44,[7,8]],
		"/(funnel)/(booking)/booking/accounts/signup": [~46,[7,8]],
		"/(funnel)/(booking)/booking/lego-demo": [47,[7,8]],
		"/(funnel)/(booking)/booking/payments": [48,[7,8,9]],
		"/(funnel)/(booking)/booking/payments/add_card": [~49,[7,8,9]],
		"/(funnel)/(booking)/booking/payments/buy": [50,[7,8,9]],
		"/(funnel)/(booking)/booking/payments/card_added": [~51,[7,8,9]],
		"/(funnel)/(booking)/booking/step-complete": [52,[7,8]],
		"/(funnel)/(booking)/booking/step-one": [53,[7,8]],
		"/(funnel)/(booking)/booking/step-start": [54,[7,8]],
		"/(funnel)/(booking)/booking/step-three": [55,[7,8]],
		"/(funnel)/(booking)/booking/step-two": [56,[7,8]],
		"/(funnel)/(booking)/booking/worker": [~57,[7,8]],
		"/(auth)/book/feedback/[key]": [~42,[6]],
		"/(funnel)/(location)/location": [58,[7,10]],
		"/sentry-example": [60],
		"/sweepstar/[username]": [~61,[12]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';